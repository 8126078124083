
<div fxLayoutAlign="center center" fxLayout="column" style="text-align: center;">
    <div mat-dialog-content fxLayoutAlign="center center">Are you sure you want to logout?</div>

    <div mat-dialog-actions fxLayoutAlign="center center" fxLayout="row" class="margin-top">
        <button mat-button mat-raised-button color="primary" class="Submit"
        (click)="logout()">Yes</button>
        <button mat-button mat-raised-button color="warn" class="add-button" mat-dialog-close>No</button>

    </div>
</div>