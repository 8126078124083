import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { admin } from '../_models/admin';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentAdminSubject: BehaviorSubject<admin>;
  public currentAdmin: Observable<admin>;

  constructor(private http: HttpClient) {

    this.currentAdminSubject = new BehaviorSubject<admin>(JSON.parse(localStorage.getItem('currentAdmin') || '{}'));
    this.currentAdmin = this.currentAdminSubject.asObservable();
  }

  public get currentAdminValue(): admin {
    return this.currentAdminSubject.value;
  }
  private handleError(error: any) {
    return throwError(error);
  }


  userLogin(formdata: any) {
    return this.http.post<any>(`${environment.apiUrl}/users/login`, formdata)
      .pipe(map(admin => {

        console.log('admin', admin)

        admin.authdata = admin.data.token
        localStorage.setItem('currentAdmin', JSON.stringify(admin));
        // localStorage.setItem('access_token', admin.authdata);

        this.currentAdminSubject.next(admin);
        return admin;
      }));
  }

  logout() {
    return this.http.get(`${environment.apiUrl}/users/logout`)
      .pipe(catchError(this.handleError));
  }

}
