import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }
  private handleError(error: any) {
    return throwError(error);
  }

  changePass(formdata:any) {
    return this.http.post<any>(`${environment.apiUrl}/users/changePassword`, formdata )
    .pipe(map(admin => {
    }));
  }

  getRole(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/getroles`)
      .pipe(catchError(this.handleError));
  }

  changeRole(formdata:any) {
    return this.http.post<any>(`${environment.apiUrl}/users/changeRole`, formdata )
    .pipe(map(admin => {
    }));
  }

  updateProfile(formdata:any) {
    return this.http.post<any>(`${environment.apiUrl}/update/user`, formdata )
    .pipe(map(admin => {
    }));
  }

  getUserProfile(formdata:any){
    return this.http.post<any>(`${environment.apiUrl}/userInfo`, formdata )
    .pipe(catchError(this.handleError));
  }

  private subjectName = new Subject<any>(); //need to create a subject
  
  sendUpdate(message: string) { //the component that wants to update something, calls this fn
      this.subjectName.next({ text: message }); //next() will feed the value in Subject
  }

  getUpdate(): Observable<any> { //the receiver component calls this function 
      return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }
}
