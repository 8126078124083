import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }
  private handleError(error: any) {
    return throwError(error);
  }

  getOrder(payload:any) {
    if(payload == ""){
      return this.http.get<any>(`${environment.apiUrl}/orderhistory`)
      .pipe(catchError(this.handleError));
    }else if(payload.start_date){
      return this.http.get<any>(`${environment.apiUrl}/orderhistory?start_date=${payload.start_date}&end_date=${payload.end_date}`)
      .pipe(catchError(this.handleError));
    }else{
      return this.http.get<any>(`${environment.apiUrl}/orderhistory?order_status_id=${payload}`)
      .pipe(catchError(this.handleError));
    }
  }

  deleteOrder(formData: any){
    return this.http
    .post(`${environment.apiUrl}/delete/order`, formData)
      .pipe(catchError(this.handleError));
  }

  updateOrder(formData: any){
    return this.http
    .post(`${environment.apiUrl}/update/order`, formData)
      .pipe(catchError(this.handleError));
  }

  orderInfo(formData:any){
    return this.http
    .post(`${environment.apiUrl}/orderInfo`, formData)
      .pipe(catchError(this.handleError));
  }

  addPayment(formData: any){
    return this.http
    .post(`${environment.apiUrl}/add/orderPayment`, formData)
      .pipe(catchError(this.handleError));
  }

  deletePayment(formData: any){
    return this.http
    .post(`${environment.apiUrl}/delete/orderPayment`, formData)
      .pipe(catchError(this.handleError));
  }

  addFollowUp(formData: any){
    return this.http
    .post(`${environment.apiUrl}/addFollowUp`, formData)
      .pipe(catchError(this.handleError));
  }

  orderPayInfo(formData:any){
    return this.http
    .post(`${environment.apiUrl}/orderPaymentHistory`, formData)
      .pipe(catchError(this.handleError));
  }

  updateOrderPay(formData:any){
    return this.http
    .post(`${environment.apiUrl}/update/orderPayment`, formData)
      .pipe(catchError(this.handleError));
  }
}
