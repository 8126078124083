import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: LogoutDialogComponent,
    public dialog: MatDialog,) { }

  ngOnInit(): void {
  }

  logout(){
    this.dialogRef.close('true');    
  }

}
