import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {

  constructor(private http: HttpClient) { }
  private handleError(error: any) {
    return throwError(error);
  }

  addVendor(formdata:any) {
    return this.http.post<any>(`${environment.apiUrl}/add/vendor`, formdata )
    .pipe(map(resp => {

      // console.log('resp', resp)
    }));
  }

  getAllVendor(payload:any): Observable<any> {
    if(payload == ""){
      return this.http.get<any>(`${environment.apiUrl}/vendorhistory`)
        .pipe(catchError(this.handleError));
    }else{
      return this.http.get<any>(`${environment.apiUrl}/vendorhistory?start_date=${payload.start_date}&end_date=${payload.end_date}`)
        .pipe(catchError(this.handleError));
    }
  }

  updatevendorById(formdata:any){
    return this.http.post<any>(`${environment.apiUrl}/update/vendor`, formdata )
    .pipe(map(resp => {

      // console.log('resp', resp)
    }));
  }

  getVendorById(formdata:any){
    return this.http.post<any>(`${environment.apiUrl}/show/vendor`, formdata)
      .pipe(catchError(this.handleError));
  }

  getServices(){
    return this.http.get<any>(`${environment.apiUrl}/servicePurchasedHistory`)
      .pipe(catchError(this.handleError));
  }

  getPayment(payload:any){
    if(payload == ""){
      return this.http.get<any>(`${environment.apiUrl}/agencyPaymentHistory`)
        .pipe(catchError(this.handleError));
    }else{
      return this.http.get<any>(`${environment.apiUrl}/agencyPaymentHistory?start_date=${payload.start_date}&end_date=${payload.end_date}`)
        .pipe(catchError(this.handleError));
    }
  }

  vendorPaymentById(formData:any){
    return this.http.post<any>(`${environment.apiUrl}/vendorPaymentHistory`, formData)
      .pipe(catchError(this.handleError));
  }

  deleteVendorById(formData: any){
    return this.http
    .post(`${environment.apiUrl}/delete/vendor`, formData)
      .pipe(catchError(this.handleError));
  }

  getServiceList(){
    return this.http.get<any>(`${environment.apiUrl}/servicelist`)
    .pipe(catchError(this.handleError));
  }

  addPayment(formdata:any){
    return this.http.post<any>(`${environment.apiUrl}/add/vendorPayment`, formdata )
    .pipe(catchError(this.handleError));
  }

  addService(formdata:any){
    return this.http.post<any>(`${environment.apiUrl}/add/vendorService`, formdata )
    .pipe(catchError(this.handleError));
  }

  editService(formdata:any){
    return this.http.post<any>(`${environment.apiUrl}/update/vendorService`, formdata )
    .pipe(catchError(this.handleError));
  }

  deletePayment(formdata:any){
    return this.http.post<any>(`${environment.apiUrl}/delete/vendorPayment`, formdata )
    .pipe(map(resp => {
    }))
  }

  deleteVendorSer(formdata:any){
    return this.http.post<any>(`${environment.apiUrl}/delete/vendorService`, formdata )
    .pipe(map(resp => {
    }))
  }

  vendorPaymentHistory(formdata:any){
    return this.http.post<any>(`${environment.apiUrl}/vendorPaymentHistory`, formdata )
    .pipe(catchError(this.handleError));
  }

  updateVendorPayment(formdata:any){
    return this.http.post<any>(`${environment.apiUrl}/update/vendorPayment`, formdata )
    .pipe(catchError(this.handleError));
  }
}
