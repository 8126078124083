<ng-container *ngIf="loading$ | async">
    <!-- <div class="overlay">
        <div class="center">
            <mat-progress-spinner diameter=50 [mode]="'indeterminate'">
            </mat-progress-spinner>
        </div>
    </div> -->
    <div  *ngIf="loading$ | async" class="cssload-container">
        <div class="cssload-speeding-wheel"></div>
    </div>

</ng-container>
<router-outlet></router-outlet>
