import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-signature-dialog',
  templateUrl: './signature-dialog.component.html',
  styleUrls: ['./signature-dialog.component.scss']
})
export class SignatureDialogComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<SignatureDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: SignatureDialogComponent,) {
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close('cancel');
  }

  confirmation() {
    this.dialogRef.close('okay');
  }
}
