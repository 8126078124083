import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignaturePageComponent } from './component/admin/signature-page/signature-page.component';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot([
    { path: '', redirectTo:'admin/login', pathMatch:'full'},
    { path: 'admin', loadChildren: () => import('./component/admin/admin.module').then(m => m.AdminModule) },
    { path: 'menu', loadChildren: () => import('./component/admin/shareble-link/shareble-link.module').then(m => m.SharebleLinkModule) },
    { path: 'order', loadChildren: () => import('./component/admin/one-time-link/one-time-link.module').then(m => m.OneTimeLinkModule) },
    { path:'confirmation', component:SignaturePageComponent, pathMatch:'full'},
  ])],
  exports: [RouterModule]
})
export class AppRoutingModule { }
