import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, first, map,mergeMap } from 'rxjs/operators';
import { NavigationEnd } from '@angular/router';
import { LeadPortalService } from 'src/app/shared/services/lead-portal.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LogoutDialogComponent } from './logout-dialog/logout-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { ProfileService } from 'src/app/shared/services/profile.service';

export interface NavItem {
  displayName: string;
  disabled?: boolean;
  iconName: string;
  route?: string;
  children?: NavItem[];
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  @Input() screen: any;
  viewedPage: any;
  user: any;
  firstLetter: any;
  response: any;
  todayleadLength: any;
  notificationDetail: any;
  subscriptionName: any;
  messageReceived: any;
  loading: boolean = false;

  constructor(private observer: BreakpointObserver,private router: Router,
    private activatedRoute: ActivatedRoute, private leadService: LeadPortalService,public dialog: MatDialog, private profileService: ProfileService,
    private authService: AuthService) { 
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
        )
        .pipe(
          filter(route => route.outlet === 'primary'),
          mergeMap(route => route.data),
        )
        .subscribe((event: any) => {
          this.viewedPage = event.title; // title of page
        });

        this.subscriptionName = this.profileService.getUpdate().subscribe
        ((message:any) => { 
          this.messageReceived = message;
          console.log(this.messageReceived)
    
          if(this.messageReceived.text == "Profile Update Successfully"){
            this.getProfile()
        }});
  }

  ngOnInit(): void {

    this.user = JSON.parse(localStorage.getItem('currentAdmin') || '{}');    
    this.getProfile()
    this.getAllLead()
    
  }

  getProfile(){
    this.loading = true;
    var formData = new FormData();
    formData.append("userId", this.user.data.id);

    this.profileService.getUserProfile(formData).subscribe((result:any) => {
      this.firstLetter = result.data.fname.split(' ').map((name:any) => name[0]).join('').toUpperCase()
      this.loading = false;
    })
  }

  ngAfterViewInit(){
    this.observer.observe(['(max-width: 960px)']).subscribe(res =>{
      if(res.matches){
        this.sidenav.mode = 'over';
        this.sidenav.close();
      }else{
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    })
  }


  menuitem: NavItem [] = [
    {
      displayName: 'Dashboard',
      iconName: 'dashboard',
      route: '/admin/dashboard',
    },        
    {
      displayName: 'CRM Lead Portal',
      iconName: 'widgets',          
      children: [
        {
          displayName: 'Lead Portal',
          iconName: 'person_add',
          route: '/admin/crm-lead-portal'
        },
        {
          displayName: 'Today Follow',
          iconName: 'today',
          route: '/admin/crm-lead-portal/today-follow'
        },
        { 
          displayName: 'All Leads',
          iconName: 'assignment_turned_in',
          route: '/admin/crm-lead-portal/all-leads'
        },
        { 
          displayName: 'Monthly Leads',
          iconName: 'view_list',
          route: '/admin/crm-lead-portal/monthly-leads'
        },
      ]
    },
    {
      displayName: 'Catering',
      iconName: 'local_dining',
      route: '/admin/catering',
    },        
    {
      displayName: 'Agency',
      iconName: 'branding_watermark',
      route: '/admin/agency',
    },        
    {
      displayName: 'Order Management',
      iconName: 'assessment',
      route: '/admin/order-management',
    },        
    {
      displayName: 'Role Management',
      iconName: 'autorenew',
      route: '/admin/role-management',
    },        
    // {
    //   displayName: 'Users List',
    //   iconName: 'person',
    //   route: '/admin/user',
    // },
    {
      displayName: 'Master Page',
      iconName: 'call_merge',      
      children: [
        {
          displayName: 'Items',
          iconName: 'restaurant',
          route: '/admin/master-page/signature-item'
        },
        {
          displayName: 'Leads Source',
          iconName: 'library_books',
          route: '/admin/master-page/lead-source'
        },
        { 
          displayName: 'Function Type',
          iconName: 'storage',
          route: '/admin/master-page/function-type'
        },
        { 
          displayName: 'Category & Services',
          iconName: 'device_hub',
          route: '/admin/master-page/category-service'
        },
      ]
    },
    {
      displayName: 'Quotation',
      iconName: 'assignment',
      route: '/admin/quotation',
    }               
    // {
    //   displayName: 'Account Ledger',
    //   iconName: 'assignment_turned_in',
    //   route: '/admin/account-ledger',
    // }
    // {
    //   displayName: 'Perfiles',
    //   iconName: 'group',
    //   children: [
    //       {
    //         displayName: 'Búsqueda Perfil',
    //         iconName: 'search',
    //         route: '/busquedaperfiles'
    //       }
    //     ]
    //   }
  ];


  onLoggedout() {

    const dialogRef = this.dialog.open(LogoutDialogComponent, {
      width: 'auto',
      data:'',
      panelClass: ["dialog-design"],
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if(result == 'true'){
        this.authService.logout().subscribe(
        (data: any) => {
          localStorage.clear();
        })
      this.router.navigate(['/admin/login'])
      }
    });
  }

  getAllLead() {
    this.leadService.getReminder().pipe(first())
      .subscribe((value:any) => {
        // console.log("value", value.data);
        this.response = value.tomorrows_lead_followup_count + value.tomorrows_order_followup_count
        this.todayleadLength = this.response

      }, (error: any) => {
        console.log('error=>', error);
      })
  }

  getReminder(){
    this.getAllLead()
  }
}
