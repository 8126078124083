import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { BlankLayoutComponent } from './layout/blank-layout/blank-layout.component';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';
import { MaterialModule } from './modules/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import {ChartModule} from 'angular-highcharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpconfigInterceptor } from './shared/_helpers/httpconfig.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LogoutDialogComponent } from './layout/sidenav/logout-dialog/logout-dialog.component';


@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    AppComponent,
    SidenavComponent,
    BlankLayoutComponent,
    CommonLayoutComponent,
    LogoutDialogComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ChartModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpconfigInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
